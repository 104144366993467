import APIService from '@/service/APIService';
import http from '@/http/token-http';

class InfoService extends APIService {
  constructor() {
    super('/info');
  }

  update(data) {
    return http.put(`${this.resource}`, data)
      .then((res) => res.data);
  }
}

export default new InfoService();
