<template>
  <validator v-slot="{ handleSubmit }">
    <form class="md-layout" novalidate @submit.prevent="handleSubmit(onSubmit)">

      <md-card class="md-layout-item md-size-50 md-small-size-100">
        <md-card-header>
          <span class="md-title">{{ title }}</span>
        </md-card-header>

        <md-card-content>
          <rule name="nom" rules="required" v-slot="{errors}">
            <md-field>
              <label>Nom du magasin</label>
              <md-input name="name" v-model="info.name"/>
              <span class="input-error">{{ errors[0] }}</span>
            </md-field>
          </rule>

          <rule name="nom" rules="required" v-slot="{errors}">
            <md-field>
              <label>Adresse postale</label>
              <md-input name="name" v-model="info.address"/>
              <span class="input-error">{{ errors[0] }}</span>
            </md-field>
          </rule>

          <rule name="nom" rules="required" v-slot="{errors}">
            <md-field>
              <label>Numéro de téléphone</label>
              <md-input name="name" v-model="info.phone"/>
              <span class="input-error">{{ errors[0] }}</span>
            </md-field>
          </rule>

          <md-field>
            <label>Description</label>
            <md-textarea name="name" v-model="info.description"/>
          </md-field>

          <md-field>
            <label>Autres renseignements</label>
            <md-textarea name="name" v-model="info.others"/>
          </md-field>
        </md-card-content>

        <md-card-actions>
          <md-button type="submit" class="md-raised md-primary">Valider</md-button>
        </md-card-actions>
      </md-card>
    </form>
  </validator>
</template>

<script>

import InfoService from '@/components/info/InfoService';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import pageMixin from '@/mixins/page-mixin';
import { required } from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/fr.json';
import notificationMixin from '@/mixins/notification-mixin';

extend('required', {
  ...required,
  message: messages.required,
});
export default {
  name: 'InfoPage',
  data: () => ({
    title: 'Informations',
    info: {},
  }),
  components: {
    rule: ValidationProvider,
    validator: ValidationObserver,
  },
  mixins: [pageMixin, notificationMixin],
  methods: {
    async onSubmit() {
      try {
        await InfoService.update(this.info);
        this.showSuccess('Informations mises à jour');
      } catch (err) {
        this.handleError(err);
      }
    },
  },
  async mounted() {
    this.info = await InfoService.index();
  },
};
</script>

<style scoped>
.md-card {
  margin: auto;
}
</style>
