var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validator',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"md-layout",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('md-card',{staticClass:"md-layout-item md-size-50 md-small-size-100"},[_c('md-card-header',[_c('span',{staticClass:"md-title"},[_vm._v(_vm._s(_vm.title))])]),_c('md-card-content',[_c('rule',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v("Nom du magasin")]),_c('md-input',{attrs:{"name":"name"},model:{value:(_vm.info.name),callback:function ($$v) {_vm.$set(_vm.info, "name", $$v)},expression:"info.name"}}),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('rule',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v("Adresse postale")]),_c('md-input',{attrs:{"name":"name"},model:{value:(_vm.info.address),callback:function ($$v) {_vm.$set(_vm.info, "address", $$v)},expression:"info.address"}}),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('rule',{attrs:{"name":"nom","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('md-field',[_c('label',[_vm._v("Numéro de téléphone")]),_c('md-input',{attrs:{"name":"name"},model:{value:(_vm.info.phone),callback:function ($$v) {_vm.$set(_vm.info, "phone", $$v)},expression:"info.phone"}}),_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('md-field',[_c('label',[_vm._v("Description")]),_c('md-textarea',{attrs:{"name":"name"},model:{value:(_vm.info.description),callback:function ($$v) {_vm.$set(_vm.info, "description", $$v)},expression:"info.description"}})],1),_c('md-field',[_c('label',[_vm._v("Autres renseignements")]),_c('md-textarea',{attrs:{"name":"name"},model:{value:(_vm.info.others),callback:function ($$v) {_vm.$set(_vm.info, "others", $$v)},expression:"info.others"}})],1)],1),_c('md-card-actions',[_c('md-button',{staticClass:"md-raised md-primary",attrs:{"type":"submit"}},[_vm._v("Valider")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }